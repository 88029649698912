<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="mx-auto elevation-6">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiSackPercent }}</v-icon>
            <v-toolbar-title>Spannenrechner</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="clearForm" ref="marginForm">
            <v-card-text>
              <v-row>
                <v-col>
                  <iir-currency-input
                    label="EK (netto)"
                    dense
                    :precision="2"
                    :value.sync="ek"
                    reverse
                    ref="ek"
                    re="ref"
                    inputmode="numeric"
                  >
                  </iir-currency-input>
                </v-col>
                <v-col>
                  <iir-currency-input
                    label="VK (brutto)"
                    dense
                    :precision="2"
                    :value.sync="vk"
                    reverse
                    ref="vk"
                    re="ref"
                    inputmode="numeric"
                  >
                  </iir-currency-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">USt.</th>
                          <th class="text-center">{{ vat_rates.reduced }}%</th>
                          <th class="text-center">{{ vat_rates.full }}%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Spanne</td>
                          <td class="text-center">{{ results.margin.reduced }}%</td>
                          <td class="text-center">{{ results.margin.full }}%</td>
                        </tr>
                        <tr>
                          <td>Rohertrag</td>
                          <td class="text-center">{{ results.income.reduced }}€</td>
                          <td class="text-center">{{ results.income.full }}€</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="clearForm"
                >Löschen <v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue"
import { parse, setValue } from "vue-currency-input"

export default {
  name: "MarginView",
  components: {
    iirCurrencyInput,
  },
  props: {
    source: String,
  },
  data: () => ({
    vat_rates: { reduced: 7, full: 19 },
    ek: null,
    vk: null,
    locale: "DE",
    currency: "EUR",
  }),
  computed: {
    results() {
      if (!this.ek || !this.vk) {
        return {
          margin: { full: 0, reduced: 0 },
          income: { full: 0, reduced: 0 },
        }
      }

      const vkFullNet = this.calculateNet(this.vk, this.vat_rates.full)
      const vkReducedNet = this.calculateNet(this.vk, this.vat_rates.reduced)
      const ekVal = this.parseNumber(this.ek)

      const incomeFull = (vkFullNet - ekVal).toFixed(2)
      const incomeReduced = (vkReducedNet - ekVal).toFixed(2)

      const marginFull = (incomeFull / vkFullNet * 100).toFixed(2)
      const marginReduced = (incomeReduced / vkReducedNet * 100).toFixed(2)

      return {
        margin: {
          full: marginFull || 0,
          reduced: marginReduced || 0,
        },
        income: {
          full: incomeFull || 0,
          reduced: incomeReduced || 0,
        },
      }
    },
  },
  methods: {
    clearForm() {
      this.ek = null
      this.vk = null
      // If iir-currency-input doesn't reset automatically, then:
      setValue(this.$refs["ek"].$refs["ref"], null)
      setValue(this.$refs["vk"].$refs["ref"], null)
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: this.locale,
        currency: this.currency,
      })
    },
    calculateNet(grossValue, vatRate) {
      return this.parseNumber(grossValue) / (1 + vatRate / 100)
    },
  },
}
</script>

<style></style>
