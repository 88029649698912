<template>
  <div class="invoices">
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiReceiptTextCheck }}</v-icon>
        <v-toolbar-title>Belege</v-toolbar-title>
        <v-spacer> </v-spacer>
        <title-toolbar-print />
        <v-tooltip top v-if="is_siteadmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="d-print-none"
              rounded
              v-bind="attrs"
              v-on="on"
              :disabled="email_import_disabled"
              @click.stop="apiEmailImport()"
              ><v-icon> {{ $i.mdiImport }} </v-icon></v-btn
            >
          </template>
          <span>Import starten</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text v-if="false">
        <receipt-payment-chart :receipts="receipts"></receipt-payment-chart>
      </v-card-text>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="search"
              :prepend-inner-icon="$i.mdiMagnify"
              label="Suchen"
              :loading="false && receipts_loading ? 'accent' : false"
              clearable
            ></v-text-field
          ></v-col>
          <v-col cols="6" md="3">
            <v-checkbox
              v-model="hide_archived"
              label="Historie ausblenden"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox
              v-model="show_preview"
              label="Vorschau anzeigen"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="3" sm="3">
            <v-combobox
              v-model="receipt_supplier_filter"
              :items="receipt_suppliers"
              label="Lieferanten"
              multiple
              dense
              clearable
              chips
              deletable-chips
              hide-details
              :prepend-inner-icon="$i.mdiTruckFast"
            >
              <template v-slot:no-data>
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-avatar color="grey lighten-6">
                    <v-icon> {{ $i.mdiClose }} </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Kein Lieferant gefunden
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Es wurde kein passender Lieferant gefunden
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-combobox
              v-model="receipt_types_filter"
              :items="receipt_types"
              label="Belegart"
              multiple
              dense
              clearable
              chips
              deletable-chips
              hide-details
              :prepend-inner-icon="$i.mdiBullseyeArrow"
            >
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="receipt_type_count[item]"
                          text-color="white"
                          :color="item.color"
                          small
                          >{{ receipt_type_count[item] }}</v-chip
                        >
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :model-value="data.selected"
                  :disabled="data.disabled"
                  size="small"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  {{ data.item }}
                  <v-avatar
                    color="error"
                    v-if="receipt_type_count[data.item]"
                    right
                  >
                    {{ receipt_type_count[data.item] }}</v-avatar
                  >
                </v-chip>
              </template>
            </v-combobox>
            <!-- <v-chip-group
              v-model="receipt_types_filter"
              active-class="primary--text"
              column
              multiple
            >
              <v-chip
                v-for="receipt_type in receipt_types"
                :key="receipt_type"
                :value="receipt_type"
              >
                {{ receipt_type }}
                <v-avatar
                  color="error"
                  v-if="receipt_type_count[receipt_type]"
                  right
                >
                  {{ receipt_type_count[receipt_type] }}</v-avatar
                >
              </v-chip>
            </v-chip-group> -->
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-combobox
              v-model="receipt_payment_types_filter"
              :items="receipt_payment_types"
              label="Zahlungsart"
              multiple
              dense
              clearable
              chips
              deletable-chips
              hide-details
              :prepend-inner-icon="$i.mdiCreditCard"
            >
            </v-combobox>
            <v-chip-group
              v-model="receipt_payment_types_filter"
              active-class="primary--text"
              column
              multiple
              v-if="false"
            >
              <v-chip
                v-for="receipt_type in receipt_payment_types"
                :key="receipt_type"
                :value="receipt_type"
              >
                {{ receipt_type }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <!-- <v-combobox
              v-model="receipt_paid_filter"
              :items="receipt_paid"
              item-text="value"
              item-value="name"
              label="Zahlungsstatus"
              multiple
              dense
              clearable
              chips
              deletable-chips
              hide-details
              :prepend-inner-icon="$i.mdiBankTransfer"
            >
            </v-combobox> -->
            <v-chip-group
              v-model="receipt_paid_filter"
              active-class="primary--text"
              column
              multiple
            >
              <v-chip
                v-for="paid_obj in receipt_paid"
                :key="paid_obj.name"
                :value="paid_obj.value"
              >
                {{ paid_obj.name }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row v-if="import_response?.['unrecognized']?.length > 0">
          <v-col>
            <v-textarea
              :value="JSON.stringify(import_response['unrecognized'])"
              clearable
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-layout child-flex>
        <v-data-table
          :items="receipts_ocr"
          :headers="receipts_ocr_table"
          :show-group-by="true"
          :footer-props="{
            'items-per-page-options': [5, 50, 100, -1],
            'items-per-page-text': 'Einträge pro Seite',
            'items-per-page-all-text': 'Alle',
            'page-text': '{0}-{1} von {2}',
            'show-current-page': true,
          }"
          :sort-by="['created']"
          :sort-desc="[true]"
          class="elevation-1"
          item-key="public_id"
          mobile-breakpoint="0"
          @click:row="handleClick"
          fixed-header
          dense
          v-if="receipts_ocr && receipts_ocr.length"
        >
          <template v-slot:item.preview="{ item }">
            <receipt-data-table-preview :item="item" class="d-print-none" />
          </template>
          <template v-slot:item.supplier.name="{ item }">
            <receipt-data-table-supplier :item="item" />
          </template>
          <template v-slot:item.receipt_date="{ item }">
            {{ item.receipt_date ? $gf.germanDate(item.receipt_date) : "" }}
            <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              v-if="!item.recognized && !item.receipt_date"
            ></v-progress-circular>
          </template>
          <template v-slot:item.receipt_number="{ item }">
            {{ item.receipt_number ? item.receipt_number : "" }}
            <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              v-if="!item.recognized && !item.receipt_number"
            ></v-progress-circular>
          </template>
          <template v-slot:item.created="{ item }">
            <receipt-data-table-created :item="item" />
          </template>
          <template v-slot:item.recognized="{ item }">
            <receipt-data-table-recognized :item="item" />
          </template>
          <template v-slot:item.admin="{ item }">
            <receipt-data-table-admin :item="item" />
          </template>
        </v-data-table>
      </v-layout>
      <v-layout child-flex>
        <v-data-table
          :items="receipts"
          :headers="receipts_table"
          :options.sync="options"
          :server-items-length="total_receipts"
          :show-group-by="true"
          :footer-props="{
            'items-per-page-options': [20, 50, 100, -1],
            'items-per-page-text': 'Einträge pro Seite',
            'items-per-page-all-text': 'Alle',
            'page-text': '{0}-{1} von {2}',
            'show-current-page': true,
            'show-first-last-page': true,
          }"
          class="elevation-1"
          :search="search"
          :custom-filter="customSearch"
          item-key="public_id"
          mobile-breakpoint="0"
          @click:row="handleClick"
          fixed-header
          dense
          :item-class="getRowStyle"
        >
          <template v-slot:no-results>
            Die Filterauswahl liefert keine Ergebnisse zurück.
          </template>
          <template v-slot:no-data> Keine Daten vorhanden. </template>
          <template
            v-slot:group.header="{ items, isOpen, toggle, remove, headers }"
          >
            <!-- <td
                :colspan="`${receipts_table.length - 3}`"
                class="font-weight-bold"
                @click="toggle"
              >
                <v-icon>{{ $i.mdiTruckFast }}</v-icon>
                {{ items[0].supplier.name }}
              </td>
              <td class="text-right">
                <v-icon @click="toggle"
                  >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }}
                </v-icon>
              </td>
              <td class="text-right">
                <v-icon @click="remove">{{ $i.mdiCloseCircle }} </v-icon>
              </td> -->
            <td v-for="(header, index) in headers" class="font-weight-bold">
              <div v-if="index == 0" @click="toggle">
                <v-icon>{{ $i.mdiTruckFast }}</v-icon>
              </div>
              <div v-if="index == 1" @click="toggle">
                {{ items[0].supplier.name }}
              </div>
              <div class="text-right" v-if="index == receipts_table.length - 2">
                <!-- <v-icon @click="toggle"
                  >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }} </v-icon
                > -->
                <v-icon @click="remove">{{ $i.mdiCloseCircle }} </v-icon>
              </div>
              <div class="text-right" v-if="header['value'] == 'value_gross'">
                {{
                  $gf.parseDec(
                    items
                      .map((x) => x["value_gross"])
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      ),
                    2
                  )
                }}
              </div>
            </td>
          </template>
          <template v-slot:group.summary="{ items, isOpen, headers }">
            <!-- <td :colspan="`${receipts_table.length}`" class="font-weight-bold">
              {{
                items
                  .map((x) => x["value_gross"])
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )
              }}
              {{ headers }}
            </td> -->
            <!-- <td class="font-weight-bold text-right" v-for="header in headers">
              {{
                header["value"] == "value_gross"
                  ? items
                      .map((x) => x["value_gross"])
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      )
                  : ""
              }}
            </td> -->
          </template>
          <template v-slot:item.receipt_type="{ item }">
            <receipt-data-table-receipt-type :item="item" />
          </template>
          <template v-slot:item.value_gross="{ item }">
            {{
              item.value_gross !== null
                ? $gf.parseDec(item.value_gross, 2)
                : null
            }}
          </template>
          <template v-slot:item.preview="{ item }">
            <receipt-data-table-preview class="d-print-none" :item="item" />
          </template>
          <template v-slot:item.preview_icon="{ item }">
            <receipt-data-table-preview-icon
              class="d-print-none"
              :item="item"
            />
          </template>
          <template v-slot:item.supplier.name="{ item }">
            <receipt-data-table-supplier :item="item" />
          </template>
          <template v-slot:item.receipt_date="{ item }">
            <!-- {{ item.receipt_date ? $gf.germanDate(item.receipt_date) : "" }}
            {{ $gf.days_until_date(item.receipt_date) }}
            {{
              $gf.days_until_date(item.receipt_date) < 3 &&
              $gf.days_until_date(item.receipt_date) > -90
                ? "true"
                : "false"
            }} -->
            <receipt-data-table-receipt-date :item="item" />
          </template>
          <template v-slot:item.receipt_number="{ item }">
            {{ item.receipt_number ? item.receipt_number : "" }}
          </template>
          <template v-slot:item.created="{ item }">
            <receipt-data-table-created :item="item" />
          </template>
          <template v-slot:item.recognized="{ item }">
            <receipt-data-table-recognized :item="item" />
          </template>
          <template v-slot:item.finalized="{ item }">
            <receipt-data-table-finalized :item="item" />
          </template>
          <template v-slot:item.checked="{ item }">
            <receipt-data-table-checked :item="item" />
          </template>
          <template v-slot:item.paid="{ item }">
            <receipt-data-table-paid :item="item" />
          </template>
          <template v-slot:item.downloaded="{ item }">
            <receipt-data-table-downloaded :item="item" />
          </template>
          <template v-slot:item.archived="{ item }">
            <receipt-data-table-archived :item="item" />
          </template>
          <template v-slot:item.admin="{ item }">
            <receipt-data-table-admin :item="item" />
          </template>
        </v-data-table>
      </v-layout>
      <!-- </v-card-text> -->
      <v-btn
        class="mx-2 d-print-none"
        fab
        dark
        color="secondary"
        fixed
        right
        bottom
        append
        @click.stop="dialog = !dialog"
      >
        <v-icon dark> {{ $i.mdiPlus }} </v-icon>
      </v-btn>
      <v-dialog v-model="dialog" max-width="500px">
        <add-receipts @closed="uploadFinished" />
      </v-dialog>
    </v-card>
  </div>
</template>
  
  <script>
import AddReceipts from "@/components/receipts/AddReceipts.vue"
import ReceiptPaymentChart from "@/components/receipts/ReceiptPaymentChart.vue"
import ReceiptDeliveryNotes from "@/components/receipts/ReceiptDeliveryNotes.vue"
import ReceiptDataTablePreview from "@/components/receipts/ReceiptDataTablePreview.vue"
import ReceiptDataTablePreviewIcon from "@/components/receipts/ReceiptDataTablePreviewIcon.vue"
import ReceiptDataTableSupplier from "@/components/receipts/ReceiptDataTableSupplier.vue"
import ReceiptDataTableAdmin from "@/components/receipts/ReceiptDataTableAdmin.vue"
import ReceiptDataTablePaid from "@/components/receipts/ReceiptDataTablePaid.vue"
import ReceiptDataTableChecked from "@/components/receipts/ReceiptDataTableChecked.vue"
import ReceiptDataTableRecognized from "@/components/receipts/ReceiptDataTableRecognized.vue"
import ReceiptDataTableFinalized from "@/components/receipts/ReceiptDataTableFinalized.vue"
import ReceiptDataTableDownloaded from "@/components/receipts/ReceiptDataTableDownloaded.vue"
import ReceiptDataTableCreated from "@/components/receipts/ReceiptDataTableCreated.vue"
import ReceiptDataTableArchived from "@/components/receipts/ReceiptDataTableArchived.vue"
import ReceiptDataTableReceiptType from "@/components/receipts/ReceiptDataTableReceiptType.vue"
import receipt_types_lib from "@/assets/receipt_types.json"
import ReceiptDataTableReceiptDate from "@/components/receipts/ReceiptDataTableReceiptDate.vue"
import TitleToolbarPrint from "@/components/TitleToolbarPrint.vue"

export default {
  name: "ReceiptsOverview",
  components: {
    AddReceipts,
    ReceiptDeliveryNotes,
    ReceiptDataTablePreview,
    ReceiptDataTablePreviewIcon,
    ReceiptDataTableSupplier,
    ReceiptDataTableAdmin,
    ReceiptDataTablePaid,
    ReceiptDataTableChecked,
    ReceiptDataTableRecognized,
    ReceiptDataTableFinalized,
    ReceiptDataTableDownloaded,
    ReceiptDataTableCreated,
    ReceiptDataTableArchived,
    ReceiptDataTableReceiptType,
    ReceiptDataTableReceiptDate,
    TitleToolbarPrint,
    ReceiptPaymentChart,
  },
  data: () => ({
    debounced_search: null,
    timeout: null,
    show_preview: false,
    revenue: [],
    supplier: "",
    articles: [],
    model: "",
    dialog: false,
    dialog_delivery_notes: false,
    delivery_notes_supplier_id: null,
    delivery_notes_receipt_id: null,
    show_images: true,
    timer_refresh: "",
    mountedFinished: false,
    import_response: null,
    receipt_types: receipt_types_lib,
    receipt_payment_types: ["Lastschrift", "Überweisung"],
    email_import_disabled: false,
  }),
  computed: {
    search: {
      get() {
        return this.debounced_search
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debounced_search = val
        }, 300)
      },
    },
    receipts_loading() {
      return this.$store.state.receipts_fq_loading
    },
    is_siteadmin() {
      return this.$store.state.user_obj?.is_siteadmin ?? false
    },
    filter_query() {
      const filter = {
        archived: this.hide_archived ? false : true,
        receipt_types: this.receipt_types_filter.length
          ? this.receipt_types_filter
          : undefined,
        paid: this.receipt_paid_filter.length
          ? this.receipt_paid_filter
          : undefined,
        payment_types: this.receipt_payment_types_filter.length
          ? this.receipt_payment_types_filter
          : undefined,
        supplier: this.receipt_supplier_filter.length
          ? this.receipt_supplier_filter
          : undefined,
        fulltext: this.search || undefined,
      }

      return {
        options: this.options,
        filter: filter,
      }
    },
    total_receipts() {
      return this.$store.state.receipts_total_count ?? 0
    },
    options: {
      get() {
        return this.$store.state.receipts_options
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_OPTIONS", value)
      },
    },
    hide_archived: {
      get() {
        return this.$store.state.receipts_hide_archived
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_HIDE_ARCHIVED", value)
      },
    },
    receipt_types_filter: {
      get() {
        return this.$store.state.receipt_types_filter
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_TYPES_FILTER", value)
      },
    },
    receipt_paid_filter: {
      get() {
        return this.$store.state.receipt_paid_filter
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_PAID_FILTER", value)
      },
    },
    receipt_payment_types_filter: {
      get() {
        return this.$store.state.receipt_payment_types_filter
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_PAYMENT_TYPES_FILTER", value)
      },
    },
    receipt_supplier_filter: {
      get() {
        return this.$store.state.receipt_supplier_filter
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_SUPPLIER_FILTER", value)
      },
    },
    // receipt_types() {
    //   return this.getUniqueValuesByKey(
    //     this.$store.state.receipts || [],
    //     "receipt_type"
    //   ).sort()
    //   // let receipts = this.$store.state.receipts || [];
    //   // let u = [...new Set(receipts.map((item) => item.receipt_type))].flat();
    //   // return [...new Set(u)].sort();
    // },
    // receipt_payment_types() {
    //   return this.getUniqueValuesByKeyNested(
    //     this.$store.state.receipts || [],
    //     "billing_type"
    //   ).sort()
    //   // let receipts = this.$store.state.receipts || [];
    //   // let u = [
    //   //   ...new Set(receipts.map((item) => item.supplier.billing_type)),
    //   // ].flat();
    //   // return [...new Set(u)].sort().filter(Boolean);
    // },
    receipt_paid() {
      return [
        { value: true, name: "bezahlt" },
        { value: false, name: "nicht bezahlt" },
      ]
    },
    receipt_suppliers() {
      // return this.getUniqueValuesByKeyNested(
      //   this.$store.state.receipts || [],
      //   "name"
      // ).sort()
      return this.$store.state.receipts_suppliers
      // let receipts = this.$store.state.receipts || [];
      // let u = [...new Set(receipts.map((item) => item.supplier.name))].flat();
      // return [...new Set(u)].sort();
    },
    receipt_type_count() {
      // let u = [
      //   ...new Set(this.receipts.map((item) => [item.receipt_type])),
      // ].flat();
      // return u.reduce((a, b) => (a[b] = a[b] + 1 || 1) && a, {});
      let result = {}
      for (let i = 0; i < this.receipts.length; i++) {
        let receipt_type = this.receipts[i].receipt_type
        if (receipt_type in result) {
          result[receipt_type]++
        } else {
          result[receipt_type] = 1
        }
      }
      return result
    },
    no_ocr_count() {
      return this.$store.state.receipts_no_ocr_count
    },
    receipts_ocr() {
      let receipts = this.$store.state.receipts || []
      receipts = receipts.filter((value) => !value.recognized)
      return receipts
    },
    // receipts2() {
    //   let receipts = this.$store.state.receipts || []
    //   receipts = receipts.filter((value) => value.recognized)
    //   if (this.receipt_types_filter.length)
    //     receipts = receipts.filter(
    //       (value) => this.receipt_types_filter.includes(value.receipt_type)
    //       // this.findCommonElement(
    //       //   [value.receipt_type],
    //       //   this.receipt_types_filter
    //       // )
    //     )
    //   if (this.receipt_payment_types_filter.length)
    //     receipts = receipts.filter(
    //       (value) =>
    //         this.receipt_payment_types_filter.includes(
    //           value.supplier.billing_type
    //         )
    //       // this.findCommonElement(
    //       //   [value.supplier.billing_type],
    //       //   this.receipt_payment_types_filter
    //       // )
    //     )
    //   if (this.receipt_paid_filter.length)
    //     receipts = receipts.filter((value) =>
    //       this.receipt_paid_filter.includes(value.paid)
    //     )
    //   if (this.receipt_supplier_filter.length)
    //     receipts = receipts.filter(
    //       (value) => this.receipt_supplier_filter.includes(value.supplier.name)
    //       // this.findCommonElement(
    //       //   [value.supplier.name],
    //       //   this.receipt_supplier_filter
    //       // )
    //     )
    //   if (this.hide_archived) {
    //     receipts = receipts.filter((x) => !x.archived) || []
    //   }
    //   return receipts
    // },
    receipts() {
      const receipts = this.$store.state.receipts || []
      return receipts

      // const filteredReceipts = receipts.filter((value) => {
      //   if (!value.recognized) {
      //     return false
      //   }

      //   if (
      //     this.receipt_types_filter.length &&
      //     !this.receipt_types_filter.includes(value.receipt_type)
      //   ) {
      //     return false
      //   }

      //   if (
      //     this.receipt_payment_types_filter.length &&
      //     !this.receipt_payment_types_filter.includes(
      //       value.supplier.billing_type
      //     )
      //   ) {
      //     return false
      //   }

      //   if (
      //     this.receipt_paid_filter.length &&
      //     !this.receipt_paid_filter.includes(value.paid)
      //   ) {
      //     return false
      //   }

      //   if (
      //     this.receipt_supplier_filter.length &&
      //     !this.receipt_supplier_filter.includes(value.supplier.name)
      //   ) {
      //     return false
      //   }

      //   if (this.hide_archived && value.archived) {
      //     return false
      //   }

      //   return true
      // })

      // return filteredReceipts
    },
    receipts_ocr_table() {
      let headers = [
        {
          text: "Vorschau",
          value: "preview",
          groupable: false,
          width: 200,
        },
        {
          text: "Lieferant",
          value: "supplier.name",
          sortable: true,
          groupable: true,
          sort: (a, b) => a.name > b.name,
        },
        {
          text: "Belegart",
          value: "receipt_type",
          sortable: true,
          groupable: false,
        },
        {
          text: "Belegdatum",
          value: "receipt_date",
          sortable: true,
          groupable: false,
        },
        {
          text: "Belegnummer",
          value: "receipt_number",
          sortable: true,
          groupable: false,
        },
        {
          text: "OCR",
          value: "recognized",
          sortable: false,
          groupable: false,
        },
      ]

      // if (
      //   this.$store.state.user_obj &&
      //   this.$store.state.user_obj.is_siteadmin
      // ) {
      //   headers.push({
      //     text: "Admin",
      //     value: "admin",
      //     groupable: false,
      //   })
      // }
      return headers
    },
    receipts_table() {
      let headers = [
        {
          text: "Lieferant",
          value: "supplier.name",
          sortable: true,
          groupable: true,
          sort: (a, b) => a.name > b.name,
        },
        {
          text: "Belegart",
          value: "receipt_type",
          sortable: true,
          groupable: false,
        },
        {
          text: "Belegdatum",
          value: "receipt_date",
          sortable: true,
          groupable: false,
        },
        {
          text: "Belegnummer",
          value: "receipt_number",
          sortable: true,
          groupable: false,
        },
        // {
        //   text: "OCR",
        //   value: "recognized",
        //   sortable: false,
        //   groupable: false,
        // },
        {
          text: "Bewertet",
          value: "finalized",
          sortable: false,
          groupable: false,
        },
        {
          text: "Geprüft",
          value: "checked",
          sortable: false,
          groupable: false,
        },
        {
          text: "Brutto",
          value: "value_gross",
          sortable: false,
          groupable: false,
          align: "right",
        },
        {
          text: "Bezahlt",
          value: "paid",
          sortable: false,
          groupable: false,
        },
        {
          text: "Heruntergeladen",
          value: "downloaded",
          sortable: false,
          groupable: false,
        },
        {
          text: "Archiviert",
          value: "archived",
          sortable: false,
          groupable: false,
        },
        {
          text: "Upload",
          value: "created",
          sortable: true,
          groupable: false,
          sort: (a, b) => a > b,
        },
      ]
      if (this.show_preview) {
        headers.unshift({
          text: "Vorschau",
          value: "preview",
          groupable: false,
          width: 200,
        })
        // headers = [
        //   {
        //     text: "Vorschau",
        //     value: "preview",
        //     groupable: false,
        //     width: 200,
        //   },
        //   ...headers,
        // ];
      } else {
        headers.unshift({
          text: "",
          value: "preview_icon",
          groupable: false,
        })
        // headers = [
        //   {
        //     text: "",
        //     value: "preview_icon",
        //     groupable: false,
        //   },
        //   ...headers,
        // ];
      }
      if (this.is_siteadmin) {
        if (this.$store.state.location_id == "*") {
          headers.unshift({
            text: "Markt",
            value: "location_id",
            groupable: false,
          })
        }
        headers.push({
          text: "Admin",
          value: "admin",
          groupable: false,
        })
        // headers = [
        //   ...headers,
        //   {
        //     text: "Admin",
        //     value: "admin",
        //     groupable: false,
        //   },
        // ];
      }
      return headers
    },
  },
  watch: {
    filter_query(nVal, oVal) {
      this.$store.dispatch("setReceiptsFilterQuery", nVal)
      this.apiGetReceipts()
    },
  },
  methods: {
    onDragEnter() {
      this.dialog = true
    },
    onDragOver(event) {
      event.preventDefault() // Prevent the default drag behavior
      this.dialog = true
    },
    onDragLeave() {
      this.dialog = false
    },
    getUniqueValuesByKey(arr, key) {
      const map = new Map()
      const uniqueValues = []
      for (const obj of arr) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key]
          if (!map.has(value) && value) {
            map.set(value, true)
            uniqueValues.push(value)
          }
        }
      }
      return uniqueValues
    },
    getRowStyle(item) {
      const {
        receipt_type,
        archived,
        recognized,
        paid,
        checked,
        finalized,
        receipt_date,
        supplier,
        duplicate,
      } = item
      const paymentDaysDiscount = this.$gf.payment_days(
        receipt_date,
        supplier.payment_discount_term
      )
      const paymentDaysTerm = this.$gf.payment_days(
        receipt_date,
        supplier.payment_term
      )

      if (duplicate && !archived) {
        return "red accent-4"
      }

      if (archived) {
        return "grey darken-1"
      }

      if (!recognized) {
        return "indigo darken-3 font-weight-thin"
      }

      if (receipt_type === "Lieferschein") {
        return "lime darken-2"
      }

      if (receipt_type === "Rechnung" && !paid) {
        if (
          (paymentDaysDiscount > 0 && paymentDaysDiscount <= 1) ||
          (paymentDaysTerm && paymentDaysTerm <= 1)
        ) {
          return "error lighten-1 font-weight-black"
        }

        if (
          (paymentDaysDiscount > 0 && paymentDaysDiscount <= 3) ||
          (paymentDaysTerm && paymentDaysTerm <= 3)
        ) {
          return "orange font-weight-bold"
        }
      }

      if (paid && checked && finalized) {
        return "success darken-1"
      }

      return paid ? "secondary" : ""
    },
    getRowStyleBACKUP(item) {
      if (item.receipt_type == "Lieferschein") return "lime darken-2"
      if (item.archived) return "grey darken-1"
      if (!item.recognized) return "indigo darken-3 font-weight-thin"
      if (item.paid && item.checked && item.finalized) return "success darken-1"
      if (
        !item.paid &&
        item.receipt_type == "Rechnung" &&
        item.receipt_date &&
        ((item.supplier.payment_discount_term > 0 &&
          this.$gf.payment_days(
            item.receipt_date,
            item.supplier.payment_discount_term
          ) <= 1) ||
          (item.supplier.payment_term &&
            this.$gf.payment_days(
              item.receipt_date,
              item.supplier.payment_term
            ) <= 1))
      )
        return "error lighten-1 font-weight-black"
      if (
        !item.paid &&
        item.receipt_type == "Rechnung" &&
        item.receipt_date &&
        ((item.supplier.payment_discount_term > 0 &&
          this.$gf.payment_days(
            item.receipt_date,
            item.supplier.payment_discount_term
          ) < 3) ||
          (item.supplier.payment_term &&
            this.$gf.payment_days(
              item.receipt_date,
              item.supplier.payment_term
            ) <= 3))
      )
        return "orange font-weight-bold"
      return item.paid ? "secondary" : ""
    },
    findCommonElement(array1, array2) {
      if (array1 !== null && array2 !== null) {
        const set = new Set(array1)
        for (let i = 0; i < array2.length; i++) {
          if (set.has(array2[i])) {
            return true
          }
        }
      }
      return false
    },
    customSearch(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase())
      })
    },
    apiGetReceipts() {
      // this.$store.dispatch("apiGetReceipts", this.$store.state.location_id)
      if (
        this.$store.state.receipts_filter_query.filter_query?.options !== null
      ) {
        this.$store.dispatch("apiGetReceiptsFQ", this.$store.state.location_id)
      }
    },
    apiEmailImport() {
      this.email_import_disabled = true
      this.$api
        .get("import_receipts")
        .then((response) => {
          this.import_response = response.data
        })
        .catch()
      setTimeout(() => {
        ;(this.email_import_disabled = false), this.apiGetReceipts()
      }, 10000)
    },
    handleClick(item) {
      let params = {
        location_id: this.$route.params.location_id,
        receipt_id: item.public_id,
      }
      if (item.recognized) {
        this.$router.push({
          name: "ShowReceipt",
          params: params,
        })
      } else {
        this.$root.toast.alert("Texterkennung noch nicht abgeschlossen")
      }
    },
    zeroPad(num, places) {
      return String(num).padStart(places, "0")
    },

    uploadFinished() {
      this.dialog = false
      // this.timer_refresh = setInterval(this.apiGetReceipts, 1000 * 3);
      this.apiGetReceipts()
    },
  },
  mounted: function () {
    this.timer_refresh = setInterval(() => {
      if (this.no_ocr_count > 0) {
        this.apiGetReceipts()
      }
    }, 1000 * 5)
    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯
      this.mountedFinished = true
    })
  },
  created() {
    // console.log("on created")
    // this.apiGetReceipts()
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer_refresh) {
      clearInterval(this.timer_refresh)
    }
    next()
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav)
    })
  },
}
</script>