<template>
  <v-container fill-height class="d-flex">
    <v-layout column fill-height>
      <v-flex grow>
        <v-card class="mx-auto elevation-1" flat :disabled="dialog_disabled">
          <v-card-title>
            <v-icon> {{ $i.mdiScriptTextOutline }} </v-icon>
            Splitter
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-slider
              v-model="selected_width"
              step="20"
              ticks
              tick-size="3"
              min="100"
              max="600"
              :append-icon="$i.mdiPlus"
              :prepend-icon="$i.mdiMinus"
              @click:append="() => (selected_width += 20)"
              @click:prepend="() => (selected_width -= 20)"
            ></v-slider>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text>
            <v-container class="pa-1">
              <v-item-group v-model="selected_pages" multiple>
                <v-row v-for="(split_row, i) in split_pages">
                  <v-col
                    v-for="page in split_row"
                    :key="page.file"
                    cols="auto"
                    align-self="start"
                    :style="backgroundColorStyles(i)"
                    class="d-flex flex-column align-center"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <div>
                        <v-img
                          :src="getFullImageUrl(page)"
                          :style="width_style(page)"
                          contain
                          @click="toggle"
                          :gradient="gradient_for_page(page)"
                        >
                          <template v-slot:default>
                            <div class="overlay" v-if="isPageRemoved(page)">
                              <!-- Only the icon, centered -->
                              <v-icon color="white" size="36">{{
                                $i.mdiDelete
                              }}</v-icon>
                            </div>
                          </template>
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <div class="d-flex justify-center mt-2">
                          <v-btn-toggle>
                            <v-btn icon disabled>
                              {{ page.page + 1 }}
                            </v-btn>
                            <v-btn v-on:click.stop="removePage(page.page)" icon>
                              <v-icon>{{
                                isPageRemoved(page)
                                  ? $i.mdiDeleteOff
                                  : $i.mdiDelete
                              }}</v-icon>
                            </v-btn>

                            <v-btn v-on:click.stop="rotatePage(page.page)" icon>
                              <v-icon>{{ $i.mdiFileRotateRight }}</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                      </div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-container>
            <!-- {{ receipt.pdf }}
            <br />
            {{ split_pages }} -->
          </v-card-text>
          <v-card-actions>
            Alle Seiten:<v-btn v-on:click.stop="rotatePage()" icon>
              <v-icon>{{ $i.mdiFileRotateRight }}</v-icon>
            </v-btn>
            <v-btn color="success" v-on:click="apiSplitReceipt()"
              >Splitten<v-icon right>{{ $i.mdiContentSave }}</v-icon></v-btn
            >
          </v-card-actions>
          <v-btn
            class="mt-10"
            fab
            dark
            small
            top
            right
            absolute
            color="primary"
            @click="closed()"
          >
            <v-icon dark> {{ $i.mdiClose }} </v-icon>
          </v-btn>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
    
    <script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue";

export default {
  name: "ReceiptEditor",
  components: {
    iirCurrencyInput,
  },
  props: {
    receipt: { default: null },
  },
  data: () => ({
    selected_pages: [],
    selected_width: 200,
    nonce: 0,
    dialog_disabled: false,
    rgbaColors: [
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 255, 153, 0.7)", // Light Yellow with 70% transparency
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(221, 160, 221, 0.7)", // Light Purple with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 218, 185, 0.7)", // Peach with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(255, 160, 122, 0.7)", // Light Salmon with 70% transparency
      "rgba(255, 105, 180, 0.7)", // Light Pinkish Red with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 255, 153, 0.7)", // Light Yellow with 70% transparency
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(221, 160, 221, 0.7)", // Light Purple with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 218, 185, 0.7)", // Peach with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(255, 160, 122, 0.7)", // Light Salmon with 70% transparency
    ],
  }),
  computed: {
    backgroundColorStyles() {
      return (i) =>
        `background-color: ${this.rgbaColors[i % this.rgbaColors.length]}`;
    },
    split_pages() {
      if (!this.receipt || !this.receipt.pdf || !this.receipt.pdf.pages)
        return [];
      const pages = this.receipt.pdf.pages;
      const pageNumbers = [...this.selected_pages].sort((a, b) => a - b);
      // Ensure last page is included as a split end
      if (!pageNumbers.includes(pages.length - 1)) {
        pageNumbers.push(pages.length - 1);
      }

      const resultArray = [];
      let start = -1;
      for (const end of pageNumbers) {
        const chunk = pages
          .filter((p) => p.page > start && p.page <= end)
          .map(({ file, page, rotation, removed }) => ({
            file,
            page,
            rotation,
            removed,
          }));
        resultArray.push(chunk);
        start = end;
      }
      return resultArray;
    },
  },
  methods: {
    apiSplitReceipt() {
      this.dialog_disabled = true;
      this.$api
        .post(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/edit",
          this.split_pages
        )
        .then(() => {
          this.$router.back();
          this.closed();
        })
        .catch()
        .then();
    },
    isPageRemoved(page) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === page.page
      );
      if (pageIndex === -1) return false;

      const pageObj = this.receipt.pdf.pages[pageIndex];
      return !!pageObj.removed;
    },
    gradient_for_page(page) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === page.page
      );
      if (pageIndex === -1) return "";

      const pageObj = this.receipt.pdf.pages[pageIndex];
      return pageObj.removed
        ? "to top right, rgba(255,0,0,.33), rgba(255,0,0,.7)"
        : "";
    },
    width_style(page) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === page.page
      );
      if (pageIndex === -1) return "";

      const pageObj = this.receipt.pdf.pages[pageIndex];

      const aspectRatio = 1.414; // Original aspect ratio
      const W = this.selected_width; // Desired bounding box width
      const H = this.selected_width * aspectRatio; // Desired bounding box height
      const rotation = pageObj.rotation || 0;

      let scale = 1;

      // If rotated by 90° or 270°, the image dimension flips and we need to scale
      if (rotation === 90 || rotation === 270) {
        scale = Math.min(W / H, H / W);
      }

      return `
    width: ${W}px;
    height: ${H}px;
    transform: rotate(${rotation}deg) scale(${scale});
    transform-origin: center center;
  `;
    },
    removePage(page) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === page
      );
      if (pageIndex === -1) return;

      const pageObj = this.receipt.pdf.pages[pageIndex];

      // Ensure removed is reactive
      if (typeof pageObj.removed === "undefined") {
        this.$set(this.receipt.pdf.pages[pageIndex], "removed", false);
      }

      // Toggle the removed state
      const currentlyRemoved = pageObj.removed;
      this.$set(
        this.receipt.pdf.pages[pageIndex],
        "removed",
        !currentlyRemoved
      );
    },
    rotatePage(pageNumber) {
      // If no pageNumber is provided, rotate all pages
      if (pageNumber == null) {
        this.receipt.pdf.pages.forEach((pageObj, index) => {
          const newRotation = ((pageObj.rotation || 0) + 90) % 360;
          this.$set(this.receipt.pdf.pages[index], "rotation", newRotation);
        });
        return;
      }

      // Otherwise, rotate just the specified page
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === pageNumber
      );
      if (pageIndex === -1) return;

      const pageObj = this.receipt.pdf.pages[pageIndex];
      const newRotation = ((pageObj.rotation || 0) + 90) % 360;
      this.$set(this.receipt.pdf.pages[pageIndex], "rotation", newRotation);
    },
    closed() {
      this.$emit("closed");
    },
    checked() {
      this.apiPutReceipt({ checked: true });
      this.closed();
    },
    getFullImageUrl(page) {
      return `${this.$api_url}/files/invoices/${
        this.receipt.public_id
      }/${page.file.replace(".jpg", "_1600.jpg")}?${this.nonce}`;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.nonce = parseInt(new Date().getTime() / 10000);
    this.receipt.pdf.pages = this.receipt.pdf.pages.map((page) => ({
      ...page,
      rotation: page.rotation || 0,
      removed: page.removed || false,
    }));
  },
};
</script>
    
    <style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.3); /* Semi-transparent red overlay */
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  text-align: center;
}
</style>
    