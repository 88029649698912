<template>
  <v-card class="mx-auto elevation-1" flat>
    <v-form @submit.prevent="clearForm" ref="marginFOrm">
      <v-card-actions v-show="receipt.receipt_type != 'Rechnung'">
        <v-btn
          color="success"
          v-on:click="generatePdf()"
          v-show="!receipt.finalized"
          >Finalisieren<v-icon right>{{ $i.mdiContentSave }}</v-icon></v-btn
        >
      </v-card-actions>
      <v-card-text>
        <v-row class="fill-height">
          <v-col
            sm="12"
            md="4"
            lg="3"
            v-show="receipt.receipt_type == 'Rechnung'"
          >
            <v-card :disabled="disabled">
              <v-row>
                <v-card-actions>
                  <v-spacer />
                  <v-btn icon @click="numrows++" color="success">
                    <v-icon dark> {{ $i.mdiPlus }} </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="numrows > 1 ? numrows-- : null"
                    color="error"
                  >
                    <v-icon dark> {{ $i.mdiMinus }} </v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-checkbox
                    v-model="net_as_gross"
                    label="Netto als Brutto"
                    @click="setSums"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="include_net"
                    label="Netto mit abdrucken"
                  ></v-checkbox>
                </v-card-actions>
              </v-row>
              <v-row v-show="extended_content">
                <v-card-actions>
                  <v-btn
                    small
                    color="secondary"
                    v-on:click="set_net_values()"
                    v-show="
                      'value_net_reduced' in extended_content_filtered ||
                      'value_net_full' in extended_content_filtered
                    "
                    >{{ vat_rates.reduced }}%:
                    {{
                      $gf.parseDec(
                        extended_content_filtered.value_net_reduced,
                        2
                      )
                    }}
                    <v-icon right>{{ $i.mdiCheckCircle }}</v-icon></v-btn
                  >
                  <v-spacer />
                  <v-btn
                    small
                    color="secondary"
                    v-on:click="set_net_values()"
                    v-show="
                      'value_net_full' in extended_content_filtered ||
                      'value_net_full' in extended_content_filtered
                    "
                    >{{ vat_rates.full }}%:
                    {{
                      $gf.parseDec(extended_content_filtered.value_net_full, 2)
                    }}
                    <v-icon right>{{ $i.mdiCheckCircle }}</v-icon></v-btn
                  >
                </v-card-actions>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row class="text-center">
                    <v-col
                      ><p class="text-center">
                        {{ vat_rates.reduced }}%
                      </p></v-col
                    >
                  </v-row>
                  <v-row v-for="n in numrows" v-bind:key="n" class="mx-1">
                    <iir-currency-input
                      dense
                      :precision="2"
                      :value.sync="eingaben_reduced[n]"
                      reverse
                      :ref="'reduced-' + n"
                      re="ref"
                      :tabindex="n"
                      :disabled="receipt.finalized"
                    >
                    </iir-currency-input
                  ></v-row>
                  <v-row class="mx-1">
                    Summe netto:
                    <iir-currency-input
                      dense
                      disabled
                      :precision="2"
                      re="ref_reduced"
                      ref="ref_reduced"
                      reverse
                      :value.sync="values_reduced"
                    >
                    </iir-currency-input>
                  </v-row>
                  <v-row class="mx-1">
                    <v-slider
                      v-model="margin_reduced"
                      step="1"
                      ticks
                      max="60"
                      :label="'Spanne: ' + margin_reduced + '%'"
                      tabindex="-1"
                      :disabled="receipt.finalized"
                    ></v-slider>
                  </v-row>
                  <v-row class="mx-1">
                    VK brutto ({{ vat_rates.reduced }}%):
                    <iir-currency-input
                      dense
                      disabled
                      :precision="2"
                      re="ref_vk_reduced"
                      ref="ref_vk_reduced"
                      reverse
                      :value.sync="values_vk_reduced"
                    >
                    </iir-currency-input
                  ></v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col>
                      <p class="text-center">{{ vat_rates.full }}%</p>
                    </v-col>
                  </v-row>
                  <v-row v-for="m in numrows" v-bind:key="m" class="mx-1">
                    <iir-currency-input
                      dense
                      :precision="2"
                      :value.sync="eingaben_full[m]"
                      reverse
                      :ref="`full-${m}`"
                      re="ref"
                      :tabindex="m + numrows"
                      :disabled="receipt.finalized"
                    >
                    </iir-currency-input
                  ></v-row>
                  <v-row class="mx-1">
                    Summe netto:
                    <iir-currency-input
                      dense
                      disabled
                      :precision="2"
                      re="ref_full"
                      ref="ref_full"
                      reverse
                      :value.sync="values_full"
                    >
                    </iir-currency-input
                  ></v-row>
                  <v-row class="mx-1">
                    <v-slider
                      v-model="margin_full"
                      step="1"
                      ticks
                      max="60"
                      :label="'Spanne: ' + margin_full + '%'"
                      tabindex="-1"
                      :disabled="receipt.finalized"
                    ></v-slider>
                  </v-row>
                  <v-row class="mx-1">
                    VK brutto ({{ vat_rates.full }}%):
                    <iir-currency-input
                      dense
                      disabled
                      :precision="2"
                      re="ref_vk_full"
                      ref="ref_vk_full"
                      reverse
                      :value.sync="values_vk_full"
                    >
                    </iir-currency-input
                  ></v-row>
                </v-col>
              </v-row>
              <v-row class="mx-1">
                <v-chip-group
                  v-model="market_selected"
                  active-class="secondary--text"
                  column
                >
                  <v-chip
                    v-for="market in markets"
                    :key="market.id"
                    :value="market.id"
                  >
                    <v-avatar color="primary" left>{{ market.id }}</v-avatar>
                    {{ market.name }}
                  </v-chip>
                </v-chip-group>
              </v-row>
              <v-row class="mx-1">
                <v-chip-group
                  v-model="department_selected"
                  active-class="secondary--text"
                  column
                >
                  <v-chip
                    v-for="department in departments"
                    :key="department.id"
                    :value="department.id"
                  >
                    <v-avatar color="primary" left>{{
                      department.id
                    }}</v-avatar>
                    {{ department.name }}
                  </v-chip>
                </v-chip-group>
                <v-chip @click="dialog_add_department = !dialog_add_department">
                  <v-avatar color="success" left>+</v-avatar>
                  hinzufügen
                </v-chip>
                <v-dialog v-model="dialog_add_department" max-width="500px">
                  <v-card>
                    <v-form @submit.prevent="dialog_add_department = false">
                      <v-card-title>
                        <span class="headline">Abteilung hinzufügen</span>
                      </v-card-title>
                      <v-card-text>
                        <!-- <v-container>
                          <v-layout wrap>
                            <v-row>
                              <v-col cols="12" class="d-flex"> -->
                        <v-autocomplete
                          v-model="departments_shown"
                          :items="departments_all"
                          :prepend-icon="$i.mdiNumeric1Box"
                          item-text="name"
                          item-value="id"
                          label="Abteilung"
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item>
                              <v-list-item-avatar color="grey lighten-6">
                                <v-icon> {{ $i.mdiClose }} </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Nicht gefunden
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Es wurde keine passende Abteilung gefunden
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>

                          <template v-slot:item="{ item }">
                            <v-list-item-avatar color="grey lighten-6" dense>
                              {{ item.id }}
                            </v-list-item-avatar>
                            <v-list-item-content dense>{{
                              item.name
                            }}</v-list-item-content>
                          </template>
                          <template v-slot:selection="{ item }">
                            <v-chip small>
                              <span>{{ item.id }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete>
                        <!-- </v-col>
                            </v-row>
                          </v-layout>
                        </v-container> -->
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          @click="dialog_add_department = false"
                        >
                          schließen
                          <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-row class="mx-1">
                <v-btn
                  color="primary"
                  v-on:click="addSticker()"
                  :disabled="receipt.finalized"
                  >Bewertung hinzufügen<v-icon right>{{
                    $i.mdiPlus
                  }}</v-icon></v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-on:click="clearForm()"
                  :disabled="receipt.finalized"
                  >Leeren<v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
                >
              </v-row>
              <v-row class="mx-1 d-flex">
                <v-card class="elevation-0" width="100%">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      v-on:click="generatePdf()"
                      v-show="!receipt.finalized"
                      >Speichern<v-icon right>{{
                        $i.mdiContentSave
                      }}</v-icon></v-btn
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          v-on:click="generatePdf((generate_only = false))"
                          v-show="!receipt.finalized"
                          v-bind="attrs"
                          v-on="on"
                          >speichern &amp; mailen<v-icon right>{{
                            $i.mdiContentSave
                          }}</v-icon></v-btn
                        >
                      </template>
                      <span
                        >Der Beleg wird an die hinterlegte Kaufleute-Mailadresse
                        für die Weiterleitung zur Buchhaltung gesendet.</span
                      >
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="white"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          v-show="false"
                        >
                          {{ $i.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span
                        >Finalisieren erst nach Eingabe der Metadaten
                        (Lieferant, Belegdatum und -nummer) möglich</span
                      >
                    </v-tooltip>
                    <v-btn
                      color="success"
                      v-on:click="reopenPdf()"
                      v-show="receipt.finalized"
                      >Bearbeiten<v-icon right>{{
                        $i.mdiPencil
                      }}</v-icon></v-btn
                    ><v-spacer></v-spacer>
                  </v-card-actions>
                  <v-list flat v-if="stickers.length" two-line>
                    <v-subheader>Bewertungen</v-subheader>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, i) in stickers"
                        :key="i + item.market + item.department"
                      >
                        <v-list-item-avatar>
                          <v-avatar color="primary" left>
                            {{ item.department }}
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ markets.find((a) => a.id == item.market).name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-chip>
                              <v-avatar color="primary" left>
                                {{ vat_rates.reduced }}%
                              </v-avatar>
                              {{ item.vk_reduced }}
                            </v-chip>
                            <v-chip>
                              <v-avatar color="primary" left>
                                {{ vat_rates.full }}%
                              </v-avatar>
                              {{ item.vk_full }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon :disabled="receipt.finalized">
                            <v-icon @click="remove_sticker(item)">{{
                              $i.mdiCloseCircle
                            }}</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="12" md="8" lg="9" min-width="820" class="fill-height">
            <v-flex text-xs-center>
              <v-card width="800" v-if="receipt" class="fill-height">
                <v-row>
                  <v-col
                    style="overflow-y: visible !important"
                    class="fill-height"
                  >
                    <v-tabs
                      v-if="receipt.pdf"
                      v-model="active_page"
                      icons-and-text
                    >
                      <v-tab
                        v-for="page in receipt.pdf.pages"
                        :key="page.file"
                        style="height: 100px"
                      >
                        <v-card tile>
                          <v-btn
                            fab
                            x-small
                            absolute
                            left
                            top
                            class="mt-4"
                            style="opacity: 0.6"
                            elevation="2"
                            :disabled="
                              receipt.pdf.pages.length == 1 || disabled
                            "
                            >{{ page.page + 1 }}</v-btn
                          >
                          <!--<v-icon>{{ $i.mdiDelete }}</v-icon></v-btn -->

                          <v-img
                            :src="`${$api_url}/files/invoices/${
                              receipt.public_id
                            }/${page.file.replace(
                              '.jpg',
                              '_200.jpg'
                            )}?${nonce}`"
                            style="width: 50px"
                            contain
                            :gradient="
                              page.removed
                                ? 'to top right, rgba(255,0,0,.33), rgba(255,0,0,.7)'
                                : ''
                            "
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                            <v-card-title
                              class="align-end fill-height"
                              primary-title
                            >
                            </v-card-title>
                          </v-img>
                        </v-card>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-row v-if="receipt.pdf"
                  ><v-col>
                    <v-card>
                      <v-btn-toggle>
                        <v-btn disabled>
                          Seite {{ active_page + 1 }} bearbeiten
                        </v-btn>
                        <v-btn
                          v-on:click.stop="rotatePage(active_page)"
                          icon
                          style="opacity: 0.6"
                          elevation="2"
                          :disabled="receipt.pdf.pages.length == 1 || disabled"
                          ><v-icon>{{ $i.mdiFileRotateRight }}</v-icon></v-btn
                        >
                        <v-btn
                          v-on:click.stop="removePage(active_page)"
                          icon
                          style="opacity: 0.6"
                          elevation="2"
                          :disabled="receipt.pdf.pages.length == 1 || disabled"
                          ><v-icon>{{ $i.mdiDelete }}</v-icon></v-btn
                        >
                      </v-btn-toggle>
                    </v-card>
                  </v-col></v-row
                >
                <v-row>
                  <v-col class="fill-height" style="overflow-y: auto">
                    <v-carousel
                      v-model="active_page"
                      hide-delimiter-background
                      hide-delimiters
                      show-arrows-on-hover
                      height="1131"
                      v-if="receipt.pdf"
                    >
                      <div
                        v-draggable
                        style="z-index: 1 !important; background: #bbb"
                        v-for="sticker in stickers_active"
                        :key="sticker.market + sticker.department"
                        :value="sticker.department"
                        :ref="`div-${sticker.market}-${sticker.department}`"
                        @click="sticker_move(sticker)"
                        :style="`left:${sticker.left}px;top:${sticker.top}px`"
                        transition="scale-transition"
                        origin="center center"
                      >
                        <svg-bewertung
                          :config="sticker"
                          :ref="`sticker-${sticker.market}-${sticker.department}`"
                        >
                        </svg-bewertung>
                      </div>

                      <v-carousel-item
                        v-for="page in receipt.pdf.pages"
                        :key="page.file"
                      >
                        <vue-photo-zoom-pro
                          :high-url="`${$api_url}/files/invoices/${
                            receipt.public_id
                          }/${page.file.replace('.jpg', '_1600.jpg')}?${nonce}`"
                          type="circle"
                        >
                          <v-img
                            :src="`${$api_url}/files/invoices/${receipt.public_id}/${page.file}?${nonce}`"
                            :style="{
                              width: '800px',
                              transform: `rotate(${page.rotation || 0}deg)`,
                              transformOrigin: 'center center',
                            }"
                            ref="img_box"
                            :gradient="
                              page.removed
                                ? 'to top right, rgba(255,0,0,.33), rgba(255,0,0,.7)'
                                : ''
                            "
                          >
                            <span
                              style="
                                background-color: blue;
                                color: white;
                                font-weight: bold;
                              "
                            >
                              Info
                            </span>
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </vue-photo-zoom-pro>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-card>
            </v-flex>
          </v-col>
        </v-row>

        <!-- <v-row v-if="receipts && 'delivery_notes' in receipts && supplier.public_id">
            <v-col>
              <iir-file-table :items="receipts" subdir="delivery_notes" :supplier="supplier.public_id"></iir-file-table>
            </v-col>
          </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
</template>
  
  <script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue";
import svgBewertung from "@/components/invoicevaluation/svgBewertung.vue";
import { parse, setValue } from "vue-currency-input";
import VuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import departments_ids from "@/assets/departments_ids.json";

export default {
  name: "ReceiptValuation",
  components: {
    iirCurrencyInput,
    VuePhotoZoomPro,
    svgBewertung,
  },
  props: {
    config: { default: null },
    extended_content: { default: {} },
    disabled: { default: false },
  },
  data: () => ({
    dialog: false,
    vat_rates: { reduced: 7, full: 19 },
    net_as_gross: false,
    eingaben_reduced: { 0: 0 },
    eingaben_full: { 0: 0 },
    margin_reduced: 20,
    margin_full: 20,
    numrows: 1,
    download_file: null,
    active_page: null,
    dialog_add_department: false,
    departments_all: departments_ids,
    departments_shown: [10, 11, 30],
    markets: [
      { id: 1, name: "Hauptmarkt" },
      { id: 2, name: "Getränkemarkt" },
    ],
    department_selected: 10,
    market_selected: 1,
    stickers: [],
    include_net: false,
    ocr_finished: false,
    receipt: {},
    receipts: {},
    nonce: 0,
    values_reduced: null,
    values_vk_reduced: null,
    values_full: null,
    values_vk_full: null,
  }),
  computed: {
    extended_content_filtered() {
      if (!this.extended_content) {
        return {}; // Return an empty object if extended_content is undefined
      }
      return this.extended_content
        ?.filter((item) => item["key"].startsWith("value_"))
        .reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});
    },
    departments() {
      let d = this.departments_all.filter((a) =>
        this.departments_shown.includes(a.id)
      );
      return d;
    },
    full_sum_c: function () {
      return this.summarizeObject(this.eingaben_full);
    },
    reduced_sum_c: function () {
      return this.summarizeObject(this.eingaben_reduced);
    },
    stickers_active: function () {
      return this.stickers.filter((a) => a.page == this.active_page);
    },
  },
  methods: {
    set_net_values() {
      let net_reduced =
        this.extended_content_filtered &&
        "value_net_reduced" in this.extended_content_filtered
          ? this.extended_content_filtered["value_net_reduced"]
          : null;
      let net_full =
        this.extended_content_filtered &&
        "value_net_full" in this.extended_content_filtered
          ? this.extended_content_filtered["value_net_full"]
          : null;
      // console.log(net_reduced, net_full)
      let curr_rows = parseInt(this.numrows);
      // this.numrows++
      setValue(this.$refs["reduced-" + curr_rows][0].$refs["ref"], net_reduced);
      setValue(this.$refs["full-" + curr_rows][0].$refs["ref"], net_full);

      this.$root.toast.success("Nettowerte gesetzt, bitte prüfen");
    },
    removePage(page) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === page
      );
      if (pageIndex === -1) return;

      const pageObj = this.receipt.pdf.pages[pageIndex];

      // Ensure removed is reactive
      if (typeof pageObj.removed === "undefined") {
        this.$set(this.receipt.pdf.pages[pageIndex], "removed", false);
      }

      // Toggle the removed state
      const currentlyRemoved = pageObj.removed;
      this.$set(
        this.receipt.pdf.pages[pageIndex],
        "removed",
        !currentlyRemoved
      );

      // Adjust active_page
      if (
        page === 0 &&
        !currentlyRemoved &&
        this.receipt.pdf.pages.length >= 2
      ) {
        this.active_page = 1;
      } else {
        this.active_page = Math.max(page - 1, 0);
      }
    },
    rotatePage(pageNumber) {
      const pageIndex = this.receipt.pdf.pages.findIndex(
        (x) => x.page === pageNumber
      );
      if (pageIndex === -1) return;

      const pageObj = this.receipt.pdf.pages[pageIndex];

      // If rotation not defined, use $set to ensure reactivity
      if (typeof pageObj.rotation === "undefined") {
        this.$set(this.receipt.pdf.pages[pageIndex], "rotation", 0);
      }

      const newRotation = (pageObj.rotation + 90) % 360;
      this.$set(this.receipt.pdf.pages, pageIndex, {
        ...pageObj,
        rotation: newRotation,
      });
    },
    apiGetReceipts(supplier) {
      this.$api
        .get("/files/invoices/list/" + supplier)
        .then((response) => {
          this.receipts = response.data;
        })
        .catch();
    },
    set_margin_backup() {
      if (
        this.receipt.supplier.name !== null &&
        this.$store.state.suppliers &&
        this.$store.state.suppliers.length
      ) {
        let supplier = this.$store.state.suppliers.find(
          (x) => x.name == this.receipt.supplier.name
        );
        this.supplier = supplier;
        if (supplier !== null) {
          this.margin_reduced =
            supplier.margin_reduced !== null ? supplier.margin_reduced : 25;
          this.margin_full =
            supplier.margin_full !== null ? supplier.margin_full : 25;
          // get receipts from api
          this.apiGetReceipts(supplier.public_id);
        }
      } else {
        this.margin_reduced = 25;
        this.margin_full = 25;
      }
    },
    set_margin() {
      if (this.config.supplier && this.config.supplier.name !== null) {
        this.margin_reduced =
          "margin_reduced" in this.config.supplier &&
          this.config.supplier.margin_reduced !== null
            ? this.config.supplier.margin_reduced
            : this.margin_reduced;
        this.margin_full =
          "margin_full" in this.config.supplier &&
          this.config.supplier.margin_full !== null
            ? this.config.supplier.margin_full
            : this.margin_full;
        this.net_as_gross =
          "gross_as_net" in this.config.supplier &&
          this.config.supplier.gross_as_net !== null
            ? this.config.supplier.gross_as_net
            : this.net_as_gross;
        this.department_selected =
          "main_department" in this.config.supplier &&
          this.config.supplier.main_department !== null
            ? this.config.supplier.main_department
            : this.department_selected;
      }
    },
    sticker_move(event) {
      const targetIndex = this.stickers.findIndex(
        (a) => a.department == event.department && a.market == event.market
      );

      this.stickers[targetIndex].left =
        this.$refs[`div-${event.market}-${event.department}`][0].offsetLeft;
      this.stickers[targetIndex].top =
        this.$refs[`div-${event.market}-${event.department}`][0].offsetTop;
    },
    generatePdf(generate_only = true) {
      //   console.log(this.stickers);
      let q = [];
      q = this.stickers.map((x) => ({
        ...x,
        svg: this.$refs[`sticker-${x.market}-${x.department}`][0].$el.outerHTML,
      }));
      // /invoices/<uuid>/download/
      this.$api
        .post(
          "receipts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/generate",
          {
            stickers: q,
            pages: this.receipt.pdf.pages,
            mail_receipt: !generate_only,
            // receipt: this.receipt,
            // pages: this.invoice.pages.filter(
            //   (x) => !("removed" in x) || x.removed == false
            // ),
          },
          { withCredentials: true }
        )
        .then((response) => this.$emit("finalized", response.data))
        .catch();
      //.then(() => this.downloadPdf());
    },
    reopenPdf() {
      this.$emit("reopened", this.receipt);
    },
    downloadPdf() {
      this.$api
        .get(
          "files/invoices/" +
            this.download_file.id +
            "/" +
            this.download_file.filename,
          {
            responseType: "blob",
            params: {},
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data]) //, { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.download_file.filename);
          //document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
          }, 100);
        })
        .catch()
        .then();
    },
    mailReceipt() {
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/mail"
        )
        .then(() => {
          this.apiPutReceipt(this.$route.params.receipt_id, {
            ["downloaded"]: true,
          });
        })
        .catch();
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch();
    },
    height_cal() {
      console.log(this.$refs.img_box);
      // console.log(
      //   this.$refs.img_box[this.active_page].$el.clientHeight,
      //   this.$refs.img_box[this.active_page].$el.clientWidth
      // );
    },
    addSticker() {
      // console.log(this.$refs.ref_vk_reduced.$refs.ref_vk_reduced);
      // this.height_cal();
      if (this.full_sum_c == 0 && this.reduced_sum_c == 0) {
        this.$root.toast.alert("VK-Wert kann nicht 0 sein.");
        return false;
      }
      if (
        this.stickers.some(
          (sticker) =>
            sticker.department === this.department_selected &&
            sticker.market === this.market_selected
        )
      ) {
        alert("Abteilung bereits bewertet.");
      } else {
        let sticker = {
          department: this.department_selected,
          market: this.market_selected,
          vk_reduced: this.calculateGross(
            this.reduced_sum_c,
            this.vat_rates.reduced,
            this.margin_reduced
          ),
          vk_full: this.calculateGross(
            this.full_sum_c,
            this.vat_rates.full,
            this.margin_full
          ),
          page: this.active_page,
          // page_portrait: h > w ? true : false,
          page_portrait: this.receipt.pdf.pages.filter(
            (a) => a.page == this.active_page
          )[0].page_portrait,
          left: 250,
          top: 400,
        };
        if (this.include_net) {
          sticker["vk_full"] = sticker["vk_full"] + " " + this.full_sum_c;
          sticker["vk_reduced"] =
            sticker["vk_reduced"] + " " + this.reduced_sum_c;
        }
        this.stickers.push(sticker);
        this.clearForm();
      }
    },
    remove_sticker(sticker) {
      this.stickers = this.stickers.filter(
        (a) =>
          !(a.department == sticker.department && a.market == sticker.market)
      );
    },
    clearForm() {
      this.eingaben_reduced = { 0: 0 };
      this.eingaben_full = { 0: 0 };
      //console.log(this.$refs);
      //setValue(this.$refs["reduced1"][0].$refs["ref"], "");
      for (let i = 1; i <= this.numrows; i++) {
        setValue(this.$refs["reduced-" + i][0].$refs["ref"], null);
        setValue(this.$refs["full-" + i][0].$refs["ref"], null);
        //console.log(i);
      }
      this.department_selected = 10;
      this.market_selected = 1;
    },
    summarizeObject(val) {
      return Object.values(val).reduce((a, b) => a + this.parseNumber(b));
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: "DE",
        currency: "EUR",
      });
    },
    calculateGross(net, vat, margin) {
      if (this.net_as_gross) return net.toFixed(2);
      else return ((net / (1 - margin / 100)) * (1 + vat / 100)).toFixed(2);
    },
    setSums() {
      setValue(this.$refs.ref_full.$refs.ref_full, this.full_sum_c);
      setValue(
        this.$refs.ref_vk_full.$refs.ref_vk_full,
        this.calculateGross(
          this.full_sum_c,
          this.vat_rates.full,
          this.margin_full
        )
      );
      setValue(this.$refs.ref_reduced.$refs.ref_reduced, this.reduced_sum_c);
      setValue(
        this.$refs.ref_vk_reduced.$refs.ref_vk_reduced,
        this.calculateGross(
          this.reduced_sum_c,
          this.vat_rates.reduced,
          this.margin_reduced
        )
      );
    },
    clear() {
      this.receipt = {};
    },
  },
  watch: {
    full_sum_c: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    reduced_sum_c: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    margin_full: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    margin_reduced: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
  },
  mounted() {
    this.receipt = { ...this.config, finalized: false };
    // this.receipt.finalized = false;
    if (this.receipt) this.active_page = this.receipt.pdf.page_count - 1;
    this.set_margin();
  },
  created() {
    this.clear();
    this.nonce = parseInt(new Date().getTime() / 10000);
  },
};
</script>
  
  <style scoped>
/*.v-carousel {
    cursor: url("~@/assets/bewertung.png") 0 0, crosshair;
  }*/
</style>
  