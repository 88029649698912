import { render, staticRenderFns } from "./ReceiptDataTablePreview.vue?vue&type=template&id=998cfd3e&scoped=true"
import script from "./ReceiptDataTablePreview.vue?vue&type=script&lang=js"
export * from "./ReceiptDataTablePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998cfd3e",
  null
  
)

export default component.exports