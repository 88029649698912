<!-- App.vue -->
<template>
  <v-app>
    <v-navigation-drawer v-model="drawer.opened" app class="d-print-none">
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon large color="primary">{{ $i.mdiSemanticWeb }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title"> lehweb </v-list-item-title>
          <v-list-item-subtitle class="text-wrap">
            {{ location_str }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="drawer.selectedLink" color="primary">
          <!-- <v-list-item
            v-for="(item, i) in $store.state.links"
            :key="i"
            v-if="
              !item.dev_state &&
              !(
                item.billed == false &&
                !$store.state.user_obj.features.includes('link_preview')
              )
            "
            :to="
              $store.state.user_obj.features.includes('link_preview') &&
              !(item.billed || item.free_module)
                ? '/plans' // Redirect to the main homepage if 'link_preview' feature is present
                : item.general_link
                ? `${item.link}`
                : `/${$store.state.location_id}${item.link}`
            "
            v-show="item.general_link || $store.state.location_id"
            link
            :class="!(item.billed || item.free_module) ? 'secondary' : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ $i[item.icon] }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }} </v-list-item-title>
              <v-list-item-subtitle v-if="!(item.billed || item.free_module)">
                Pro feature
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="ma-0">
              <v-chip color="success" v-if="item.counts" small>
                {{ item.module == "notes" ? $store.state.notes_count : null }}
                {{
                  item.module == "orders" ? $store.state.orders_overdue : null
                }}
              </v-chip>
            </v-list-item-action>
          </v-list-item> -->
          <v-list-item
  v-for="(item, i) in filteredLinks"
  :key="i"
  :to="getRedirectLink(item)"
  v-show="item.general_link || $store?.state?.location_id"
  link
  :class="{ secondary: isProFeature(item) }"
>
  <v-list-item-icon>
    <v-icon>{{ $i[item.icon] }}</v-icon>
  </v-list-item-icon>

  <v-list-item-content>
    <v-list-item-title>{{ item.text }}</v-list-item-title>
    <v-list-item-subtitle v-if="isProFeature(item)">
      Pro feature
    </v-list-item-subtitle>
  </v-list-item-content>

  <v-list-item-action class="ma-0">
    <v-chip color="success" v-if="item.counts" small>
      {{ getChipCount(item) }}
    </v-chip>
  </v-list-item-action>
</v-list-item>
        </v-list-item-group>

        <v-list-item v-show="!$store.state.location_id">
          <v-list-item-action class="ma-0">
            <v-list-item-icon>
              <v-icon>{{ $i.mdiOfficeBuildingMarker }}</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kein Markt ausgewählt </v-list-item-title>
            <v-list-item-subtitle>Bitte Markt auswählen.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="d-print-none">
      <v-app-bar-nav-icon
        @click="drawer.opened = !drawer.opened"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn to="/" plain
        ><v-icon large color="primary">{{ $i.mdiSemanticWeb }}</v-icon></v-btn
      >
      <v-toolbar-title>
        lehweb
        <i class="font-italic font-weight-thin">{{ current_plan }}</i>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        open-on-click
        :close-on-content-click="false"
        bottom
        offset-y
        v-if="$store.state.location_id && $store.state.user_obj"
        v-model="filter_menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
            {{ $store.state.user_obj.name }}@{{ $store.state.location_id }}
          </v-btn> -->
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              :content="$store.state.user_departments_filter.length"
              :value="$store.state.user_departments_filter.length"
              color="green"
              overlap
            >
              <v-icon>{{ $i.mdiFilterMultiple }}</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="500">
          <iir-filter-departments />
          <v-divider></v-divider>
          <v-list-item @click.prevent="filter_menu = false">
            <template>
              <v-list-item-icon>
                <v-icon>{{ $i.mdiContentSave }}</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>speichern</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-card>
      </v-menu>
      <v-menu
        open-on-click
        :close-on-content-click="false"
        bottom
        offset-y
        v-if="$store.state.user_obj"
        v-model="notification_menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
            {{ $store.state.user_obj.name }}@{{ $store.state.location_id }}
          </v-btn> -->
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              :content="
                $store.state.user_obj.notifications.filter((a) => !a.archived)
                  .length
              "
              :value="
                $store.state.user_obj.notifications.filter((a) => !a.archived)
                  .length
              "
              color="green"
              overlap
            >
              <v-icon>{{ $i.mdiMessage }}</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="500">
          <notifications-menu
            :notifications="$store.state.user_obj.notifications"
            @close="notification_menu = !notification_menu"
          />
        </v-card>
      </v-menu>
      <v-menu open-on-hover bottom offset-y v-if="$store.state.user_obj">
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
            {{ $store.state.user_obj.name }}@{{ $store.state.location_id }}
          </v-btn> -->
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-show="!is_siteadmin">{{ $i.mdiAccountCircle }}</v-icon>
            <v-icon v-show="is_siteadmin">{{ $i.mdiShieldAccount }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title
              >{{ $store.state.user_obj.last_name }},
              {{ $store.state.user_obj.first_name }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item to="/logout">
            <v-list-item-title>logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <iir-loading-bar
        :indeterminate="true"
        :active="loading"
      ></iir-loading-bar>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="d-print-block">
      <v-banner
        v-model="show_banner"
        color="secondary"
        v-show="banner && banner.message"
      >
        <v-icon slot="icon" color="warning" size="30">
          {{ $i[banner.icon] }}
        </v-icon>
        {{ banner.message }}
        <template v-slot:actions>
          <v-btn color="primary" @click="show_banner = false">
            Schließen
          </v-btn>
        </template>
      </v-banner>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="mb-16">
        <!-- If using vue-router -->
        <router-view />
      </v-container>
      <!-- <v-list class="d-flex flex-column" height="64"></v-list> -->
    </v-main>

    <v-bottom-navigation
      app
      v-if="drawer.opened"
      horizontal
      height="30"
      class="d-print-none"
    >
      <v-btn
        :to="link.link"
        v-for="link in footer.links"
        v-bind:key="link.text"
        plain
      >
        <span>{{ link.text }}</span>
        <v-icon left>{{ link.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <iir-toast ref="iirtoast" class="d-print-none"></iir-toast>
    <module-not-billed-dialog />
  </v-app>
</template>

<script>
//import SideBar from "./pages/SideBar";
import iirLoadingBar from "@/components/iirLoadingBar.vue"
import iirToast from "@/components/iirToast.vue"
import iirFilterDepartments from "@/components/iirFilterDepartments.vue"
import NotificationsMenu from "@/components/NotificationsMenu.vue"
import ModuleNotBilledDialog from "@/components/ModuleNotBilled.Dialog.vue"

export default {
  components: {
    iirLoadingBar,
    iirToast,
    iirFilterDepartments,
    NotificationsMenu,
    ModuleNotBilledDialog,
    //SideBar,
  },
  data: () => ({
    loading: false,
    timer_cookie: "",
    timer_refresh: "",
    timer_user_obj: "",
    show_banner: true,
    drawer: {
      opened: null,
      selectedLink: 0,
    },
    filter_menu: false,
    notification_menu: false,
  }),
  methods: {
    getUser() {
      this.$store.dispatch("apiGetUser")
    },
    populateLinks() {
      this.$store.dispatch("setLinks", this.links)
    },
    checkCookie() {
      // Check whether user is already logged in
      if (!this.$store.state.logged_in && this.$route.name != "Login") {
        // this.$router.push({
        //   name: "Login",
        // });
        return false
      } else {
        //this.initialLoad();
        // Populate links to store
        // if (this.$store.state.company !== null) {
        //   this.populateLinks();
        // }
        return true
      }
    },
    initialLoad() {
      //alert(this.$store.state.location_id);
      // Initiate first API requests
      // Chech whether location_id is set
      // if (this.$store.state.location_id !== null) {
      //   // load suppliers to store if not yet loaded
      //   if (this.$store.state.suppliers == null) {
      //     this.$store.dispatch("apiGetSuppliers", {
      //       location_id: this.$store.state.location_id,
      //       active: false,
      //     });
      //   }
      //   // load notes to store if not yet loaded
      //   if (this.$store.state.notes == null) {
      //     this.$store.dispatch("apiGetNotes", this.$store.state.location_id);
      //   }
      //   // load orders_suppliers to store if not yet loaded
      //   if (this.$store.state.orders_suppliers == null) {
      //     this.$store.dispatch(
      //       "apiOrdersLoadSuppliers",
      //       this.$store.state.location_id
      //     );
      //   }
      //   // load contacts to store if not yet loaded
      //   if (this.$store.state.contacts == null) {
      //     this.$store.dispatch("apiGetContacts", this.$store.state.location_id);
      //   }
      // }
      if (this.$store.state.user_obj !== null) {
        this.populateLinks()
      }
    },
    updateStore() {
      //console.log("STORE UPDATED")
      if (this.$store.state.location_id !== null) {
        // load notes to store
        // this.$store.dispatch("apiGetNotes", this.$store.state.location_id)
        // load orders_suppliers to store
        // this.$store.dispatch(
        //   "apiOrdersLoadSuppliers",
        //   this.$store.state.location_id
        // )
        // this.$store.dispatch("apiGetUserNotifications")
        this.$store.dispatch("setLocation", this.$store.state.location_id)
      }
      return true
    },
  },
  computed: {
    banner: {
      get() {
        return (
          this.$store.state.user_obj?.banner ?? {
            icon: "mdiLicense",
            message: null,
          }
        )
      },
      set(value) {
        // this.$store.dispatch("setOrdersDepartmentsFilter", value);
        // this.$store.commit("ORDERS_FILTER_DEPARTMENTS", value)
      },
    },
    filteredLinks() {
      const links = this.$store?.state?.links || []; // Safely access $store.state.links
      const userFeatures = this.$store?.state?.user_obj?.features || [];
      const hasLinkPreview = userFeatures.includes('link_preview');

      return links.filter((item) => {
        const isProFeature = !(item.billed || item.free_module);
        return (
          !item.dev_state &&
          (!isProFeature || hasLinkPreview) // Only include if not Pro or if Pro and link_preview is enabled
        );
      });
    },
    getRedirectLink() {
      return (item) => {
        const userFeatures = this.$store?.state?.user_obj?.features || [];
        const hasLinkPreview = userFeatures.includes('link_preview');
        const locationId = this.$store?.state?.location_id || '';

        const isProFeature = !(item.billed || item.free_module);
        if (hasLinkPreview && isProFeature) {
          return '/plans';
        } else if (item.general_link) {
          return item.link;
        }
        return `/${locationId}${item.link}`;
      };
    },
    isProFeature() {
      return (item) => !(item.billed || item.free_module);
    },
    getChipCount() {
      return (item) => {
        const notesCount = this.$store?.state?.notes_count || 0;
        const ordersOverdue = this.$store?.state?.orders_overdue || 0;

        if (item.module === 'notes') return notesCount;
        if (item.module === 'orders') return ordersOverdue;
        return null;
      };
    },

    current_plan() {
      return this.$store.state.billing_plan?.name ?? null
    },
    location_str() {
      return this.$store.state.location_id || "Strecke"
    },
    is_siteadmin() {
      return this.$store.state.user_obj.is_siteadmin || false
    },
    footer() {
      return {
        links: [
          {
            text: "Impressum",
            icon: this.$i.mdiScaleBalance,
            link: "/imprint",
          },
          {
            text: "Datenschutzerklärung",
            icon: this.$i.mdiShieldLock,
            link: "/privacy",
          },
        ],
      }
    },
    loaded() {
      return this.$store.state.loadState == "loaded"
    },
    notes_count() {
      return this.$store.state.notes_count
    },
    orders_count() {
      return this.$store.state.orders_overdue
    },
    links() {
      let linklist = [
        {
          text: "Bestellungen",
          icon: this.$i.mdiBeakerPlus,
          link: "/orders",
          counts: this.orders_count,
          general_link: false,
          module: "orders",
        },
        {
          text: "Notizen",
          icon: this.$i.mdiNotebook,
          link: "/notes",
          counts: this.notes_count,
          general_link: false,
          module: "notes",
        },
        {
          text: "Aktionen",
          icon: this.$i.mdiSale,
          link: "/promotions",
          general_link: false,
          module: "promotions",
        },
        {
          text: "Lieferanten",
          icon: this.$i.mdiTruckFast,
          link: "/suppliers",
          general_link: false,
        },
        {
          text: "Kontakte",
          icon: this.$i.mdiAccountMultiple,
          link: "/contacts",
          general_link: false,
          module: "contacts",
        },
        {
          text: "Weinberater",
          icon: this.$i.mdiGlassWine,
          link: "/wineadvisor",
          general_link: false,
          module: "wineadvisor",
        },
        {
          text: "Rechnungsbewertung",
          icon: this.$i.mdiTableLargePlus,
          link: "/invoicevaluationonline",
          general_link: false,
          module: "invoicevaluationonline",
        },
        {
          text: "Belege",
          icon: this.$i.mdiReceiptTextCheck,
          link: "/receipts",
          general_link: false,
          module: "receipts",
        },
        {
          text: "Spannenrechner",
          icon: this.$i.mdiSackPercent,
          link: "/margin",
          general_link: false,
          module: "margin",
        },
        {
          text: "Umsatz",
          icon: this.$i.mdiFinance,
          link: "/revenue",
          general_link: false,
          module: "revenue",
        },
        {
          text: "Wareneingänge",
          icon: this.$i.mdiPackageVariant,
          link: "/shipments",
          general_link: false,
          module: "shipments",
        },
        {
          text: "Aufgaben",
          icon: this.$i.mdiCalendarCheck,
          link: "/tasks",
          general_link: false,
          module: "tasks",
        },

        {
          text: "Analytics",
          icon: this.$i.mdiFileChart,
          link: "/analytics",
          general_link: false,
          module: "analytics",
        },
        // {
        //   text: "Audits",
        //   icon: "mdi-star-shooting",
        //   link: "/audits",
        //   general_link: false,
        // },
        // {
        //   text: "Rechnungsbewertung",
        //   icon: "mdi-table-large-plus",
        //   link: "/invoicevaluation",
        //   general_link: true,
        //   module: "invoicevaluation",
        // },

        // {
        //   text: "Audits",
        //   icon: "mdi-check-circle",
        //   link: "/audits",
        //   general_link: false,
        //   module: "audits",
        // },
        // {
        //   text: "Test",
        //   icon: "mdi-ab-testing",
        //   link: "/test",
        //   general_link: false,
        // },
      ]
      if (this.$store.state && this.$store.state.location_id) {
        // let locations = this.$store.state.user_obj.companies[0].locations;
        let active_location =
          this.$store.state.user_obj.companies
            .map((e) =>
              e.locations.filter(
                (l) => l.public_id == this.$store.state.location_id
              )
            )
            .flat() || null
        let location_id = this.$store.state.location_id
        if (location_id) {
          return linklist.filter(function (link) {
            return (
              "free_module" in link ||
              active_location
                .find((m) => m.public_id == location_id)
                .billing_modules.map((e) => {
                  return e.toLowerCase()
                })
                .includes(link.module)
            )
          })
        }
        return null
      } else {
        return null
      }
    },
  },
  mounted() {
    this.checkCookie()
    // this.timer_cookie = setInterval(this.checkCookie, 1000);
    this.timer_refresh = setInterval(this.updateStore, 1000 * 60 * 10)
    // this.timer_user_obj = setInterval(this.getUser, 1000 * 10)
    this.$root.toast = this.$refs.iirtoast
    this.$nextTick(() => {
      this.checkCookie()
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯
    })
  },
  beforeMount() {
    //console.log(process.env);
    // Add a request interceptor
    this.$api.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        this.loading = true
        return config
      },
      (error) => {
        // Do something with request error
        this.loading = false
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    this.$api.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        this.loading = false
        return response
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        this.loading = false
        if (error.response && error.response.status == 401) {
          this.$root.toast.error("Nicht eingeloggt.")
        } else {
          this.$root.toast.error(error)
        }
        return Promise.reject(error)
      }
    )
  },
  created() {
    // this.$store.dispatch("apiGetUser")
  },
}
</script>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>
<style>
body.always-scrollable {
  overflow-y: auto !important;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block; /* or inline-block */
  }
  .v-content {
    padding: 0 !important;
  }
  .v-main {
    padding: 0 !important;
    font-size: 5px; /* This sets the font size to 50% of the parent element's font size */
  }
  .v-data-table {
    display: block; /* Show only the data-table */
    width: 100%; /* Make the table full width */
    min-width: 1000px;
    overflow: visible; /* Ensure all content is visible */
    border-collapse: collapse; /* Ensures borders are crisp */
  }

  .v-data-table th,
  .v-data-table td {
    padding: 0px 2px; /* Adjust padding for table cells */
    border: 1px solid black; /* Adds borders to cells */
  }

  .v-card,
  .elevation-1,
  .elevation-2,
  .elevation-3,
  .elevation-4,
  .elevation-5 {
    box-shadow: none !important; /* Removes shadow */
    -webkit-box-shadow: none !important; /* Removes shadow for WebKit browsers */
    z-index: 0 !important; /* Resets any z-index */
  }

  @page {
    size: auto;
    margin: 0;
  }

  .v-icon {
    /* Assuming the checkbox uses an icon for its appearance */
    color: black !important; /* Change color to black */
  }
  .v-simple-checkbox {
    /* Assuming the checkbox uses an icon for its appearance */
    color: black !important; /* Change color to black */
  }

  /* Other styles to make the data-table look good in print */
}
</style>