import { render, staticRenderFns } from "./ReceiptValuation.vue?vue&type=template&id=60e37f16&scoped=true"
import script from "./ReceiptValuation.vue?vue&type=script&lang=js"
export * from "./ReceiptValuation.vue?vue&type=script&lang=js"
import style0 from "./ReceiptValuation.vue?vue&type=style&index=0&id=60e37f16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e37f16",
  null
  
)

export default component.exports